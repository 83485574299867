import divideText from './divideText';
import api from '../API/backend';

const API_URL = process.env.REACT_APP_BACKEND_PATH;

const TEXT_ERROR_MESSAGES = {
    400: 'Teseo traduce solo testi latini!',
    429: 'Teseo ha lavorato troppo per te oggi, torna domani.',
    default: 'Teseo si è perso nel labirinto e non può aiutarti ora.'
};

const analyzeText = async (inputText, lang, updateUI, updateError) => {
    try {
        const subtexts = divideText(inputText);  // Split the text into parts
        let allSuccessful = true;  // Track if all API calls succeed
    
        // Loop through each subtext and perform API analysis
        for (let i = 0; i < subtexts.length; i++) {
            try {
                const response = await api.post(`${API_URL}/analyze`, { text: subtexts[i], lang: lang });
                updateUI(response.data, i, subtexts.length);  // Update UI with the analysis result
            } catch (error) {
                handleAnalysisError(error, updateError);  // Handle individual analysis error
                allSuccessful = false;  // Set to false if any API call fails
            }
        }

        // Return true if all requests were successful, otherwise false
        return allSuccessful;

    } catch (error) {
        handleAnalysisError(error, updateError);  // Handle overall function error
        return false;  // Return false if the function fails due to an unexpected error
    }
};


const handleAnalysisError = (error, updateError) => {
    let errorMessage = 'Teseo non risponde'; // Default error message

    if (error.response) {
        const statusCode = error.response.status;
        errorMessage = TEXT_ERROR_MESSAGES[statusCode] || TEXT_ERROR_MESSAGES.default;
        updateError(errorMessage);
    } else if (error.request) {
        console.error('Request Error:', error.request);
    } else {
        console.error('Request Setup Error:', error.message);
    }

    throw new Error(errorMessage);
};

export default analyzeText;
