import React from "react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "./UserAuthContext";
import {getType, getTypeUser} from "../utils/profiloUtils";

export const ProtectedRoutes = ({ children }) => {
  const { user } = useUserAuth();
  const [type, setType] = useState(null);

  useEffect(() => {
    const fetchType = async () => {
      try {
        const userType = await getType(user);
        setType(userType);
      } catch (error) {
        console.error("Error fetching user type:", error);
        setType("normal"); // Set type to "normal" by default if there's an error
      }
    };

    if (user) {
      fetchType();
    }
  }, [user]);

  //console.log("Check user in Private: ", user);

  if (!user || type === "lifetime") { // Check if user is not logged in or has a type of "normal"
    return <Navigate to="/" />;
  }

  return children;
};


export const AuthenticateRoute = ({ children }) => {
  const { user } = useUserAuth();

  if (!user) { 
    return <Navigate to="/" />;
  }
  return children;
};

export const NonAuthenticateRoute = ({ children }) => {
  const { user } = useUserAuth();

  if (user) { 
    return <Navigate to="/" />;
  }
  return children;
};

export const NeedRegistrationRoute = ({ children }) => {
  const { isRegistered } = useUserAuth();

  if (isRegistered) { 
    return <Navigate to="/" />;
  }
  return children;
};

export const HomeProtectedRoute = ({ children }) => {
  const { isRegistered } = useUserAuth();

  if (!isRegistered) {
    return <Navigate to="/complete-signup"></Navigate>
  }
  
  return children;
};