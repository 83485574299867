import translateText from './translateText';

const handleTranslateText = async (inputText, lang, setLoading, setTranslatedText, setError, setTextError, handleUpdateError, setDisabled, user) => {

    try {
      setTextError("");
      setLoading(true);
      setDisabled(true);
      setTranslatedText('');

      const response = await translateText(inputText, lang, handleUpdateError, user);
      setTranslatedText(response);
    
    } catch (error) {
      setError(true);
      //console.log(error);
      setTextError(error.message);
    } finally {
        setLoading(false);
        setDisabled(false);
    }
  };


  export default handleTranslateText;