import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Greco from './pages/Greco';
import Login from './pages/login';
import SignupWithGoogle from './pages/signupWithGoogle';
import Signup from './pages/signup';
import Navbar from './components/Navbar';
import CheckoutYes from './stripe/CheckoutYes';
import CheckoutNo from './stripe/CheckoutNo';
import Profilo from './pages/profilo';
import Footer from './components/Footer';
import { UserAuthContextProvider } from './components/UserAuthContext';
import {NeedRegistrationRoute, AuthenticateRoute, NonAuthenticateRoute, HomeProtectedRoute} from './components/ProtectedRoutes';
import PaymentPage from './stripe/PaymentPage';

function NoMatch() {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center regular'>
        <h2 className='bold red'>404: Pagina non trovata</h2>
        <p>Ti sei pers* nel labirinto!</p>
      </div>
    );
}

function App() {
  return (
    <UserAuthContextProvider>
      <Router>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<HomeProtectedRoute><Home /></HomeProtectedRoute> }></Route>
          <Route path="/greco" element={<AuthenticateRoute> <Greco /> </AuthenticateRoute>} />
          <Route path="/login" element={<NonAuthenticateRoute> <Login /> </NonAuthenticateRoute> } />
          <Route path="/complete-signup" element={<NeedRegistrationRoute> <SignupWithGoogle /></NeedRegistrationRoute>} />
          <Route path="/signup" element={<NonAuthenticateRoute> <Signup /> </NonAuthenticateRoute>} />
          <Route path="/profilo" element={<AuthenticateRoute> <Profilo /> </AuthenticateRoute>} />
          {/* <Route path="/checkoutyes/:userid" element={<AuthenticateRoute> <CheckoutYes /> </AuthenticateRoute>} />
          <Route path="/checkoutno/:userid" element={<CheckoutNo/>} /> */}
          {/* <Route path="/pay" element={<AuthenticateRoute><PaymentPage /></AuthenticateRoute>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </UserAuthContextProvider>
    );
};

export default App;