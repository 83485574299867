import analyzeText from "./analyzeText";


const handleAnalyzeText = async (inputText, lang, setLoading, setAnalysis, setProgress, setTextError, setError, handleUpdateError, handleUpdateAnalysis, setDisabled, latinoCount=null, grecoCount=null) => {
    try {

        setTextError("");
        setLoading(true);
        setDisabled(true);
        setAnalysis({}); // Reset analysis state
        setProgress(0); // Reset progress state

        const analysisResult = await analyzeText(inputText, lang, handleUpdateAnalysis, handleUpdateError); 
        return analysisResult;

        // if((lang === "lat" && latinoCount > 0) || (lang === "gre" && grecoCount > 0 )) { // TODO
        //     const analysisResult = await analyzeText(inputText, lang, handleUpdateAnalysis, handleUpdateError); 
        //     return analysisResult;
        // }
        // else {
        //     return false;
        // }
        
      } catch (error) {
        // Handle error (e.g., display error message)
        setError(true);
        setTextError(error.message);
    } finally {
        setLoading(false);
        setDisabled(false); 
    }
};

/* const checkLimit = async(lang) => {
    try {
        const response = await api.get(`${API_URL}/limit`);
        
        const latinoCount = typeof response.data.latinoCount === 'number' ? response.data.latinoCount : Number(response.data.latinoCount);
        const grecoCount = typeof response.data.grecoCount === 'number' ? response.data.grecoCount : Number(response.data.grecoCount);

        if(lang === "lat" && latinoCount > 0 ) {
            return true;
        }
        else if (lang === "gre" && grecoCount > 0 ) {
            return true;
        }
        else {return false}
        
    } catch (error) {
        console.error('Error fetching user counts:', error);
    }
} */



export default handleAnalyzeText;
