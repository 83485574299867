import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../components/UserAuthContext";
import {customerPortal} from "../utils/stripeUtils";
import { MdVerified } from "react-icons/md";
import { sendEmailVerification, getAuth } from "firebase/auth";
import { useState } from "react";
import { Alert } from "react-bootstrap";


const API_URL = process.env.REACT_APP_BACKEND_PATH;

const Profilo = () => {
  const { logOut, user, userData } = useUserAuth();
  const userType = userData["type"];
  const customerId = userData["customerId"];
  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  /* useEffect(() => {
    const fetchReferralCode = async () => {
        try {
            if (user) { // Ensure the user is available
                const ref = await askReferralCode();
                setReferralCode(ref);
            }
        } catch (error) {
            console.error("Error fetching referral code:", error);
        }
    };

    fetchReferralCode();
}, [user]); */

/* useEffect(() => {
    const fetchUserType = async () => {
        try {
            if (user) {
                const type = await getTypeUser(user);
                console.log(type);
                setUserType(type);
            }
        } catch (error) {
            console.error("Error fetching user type:", error);
        }
    };

    fetchUserType();
}, [user]); */


    if (userType === null) {
        return <p>Loading...</p>; 
    }

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleGreco = () => {
    navigate("/greco");
  };

  const handleLatino = () => {
    navigate("/");
  };

  function reload() {
    window.location.reload();
  }

  function resendEmail() {
    if (user != null){
      const auth = getAuth();
      sendEmailVerification(auth.currentUser).then(() => {
        setMessage("Email inviata.");
        console.log("Email inviata");
      })
    }
  }

  return (
    <>
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="box_plain_teseo p-5">
        <div className="text-center bold fontXL">
        {userType==='lifetime' ? <div className="d-flex align-items-center justify-content-center"><MdVerified size={27} className='white mx-2'/><span>Teseo+ community</span></div> : <div>Teseo ti da il benvenuto!</div>}
        </div>

        {message && <Alert variant="success">{message}</Alert>}
       
        <div className="p-2 mt-3 text-center regular">
          La tua mail: {user.email} <br></br>
          {user.emailVerified ? (
            <p className="underline">La tua mail è verificata.</p>
          ) : (
            <>
              <p>Controlla il tuo Indirizzo di posta, ricordati di verificare il tuo profilo!</p>
              <Button variant="warning" onClick={reload} className="m-1">Ho verificato!</Button>
              <Button variant="warning" onClick={resendEmail} className="m-1">Invia di nuovo</Button>
            </>
          )}
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <Button className="buttonTeseo regular mx-1" onClick={handleGreco}>
            Vai al Greco
          </Button>
          <Button className="buttonTeseo regular mx-1" onClick={handleLatino}>
            Vai al Latino
          </Button>
          {/* {customerId !== "" || userType == "premium" ? 
            <Button className="buttonTeseo regular" onClick={customerPortal} disabled={!user.emailVerified}>
            Gestisci Subscription {userType}
            </Button>
            :
            ""
          } */}
        </div>
        <div className="d-flex justify-content-center my-3">
          <a className="linkTeseo regular fontM text-center mt-3" onClick={handleLogout}>Log out</a>
        </div>
      </div>
      </div>
    </>
  );
};

export default Profilo;
