import { loadStripe } from "@stripe/stripe-js";
import api from '../API/backend';

const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const customerPortal = async() => {
    try {

      const headers = {
        "Content-Type": "application/json"
      }

      const response = await api.post(
        `/user/customer-portal`,
        {
            headers: headers
        }
        );

        if (response.status === 200) {
            const session_url = await response.data;
            window.location.href = session_url.url;
        }
        
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.error('Customer not found:', error.response.data.error);
            alert('Customer not found. If you are premium, contact us');
        } else {
            console.error('Error redirecting to billing portal:', error);
            alert('An unexpected error occurred. Please try again later.');
        }
  }
}

export const payTeseo = async () => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const headers = {
        "Content-Type": "application/json"
    }

    const response = await api.post(
        `/payments/create-checkout-session`,
        {
            headers: headers
        }
    );

    const session = await response.data;
    const result = await stripe.redirectToCheckout({
        sessionId:session.id
    });

    if(result.error) {
        console.log(result.error);
    }
}
