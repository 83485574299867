import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from "../images/logo/logo_white.svg";
import styles from "../styles/Navbar.module.css"
import { FaRegUser } from 'react-icons/fa';
import { useUserAuth } from '../components/UserAuthContext';
import { MdVerified } from "react-icons/md";


export default function Navbar(props) {
    const { user, userData } = useUserAuth();
    const userType = userData["type"];
    const userName = userData["nome"];


    if (userType === null) {
        return <p>Loading...</p>;
    }

    return (
        <Container>
            <Row className='justify-content-center my-3'>
                <Col className="align-items-center d-flex justify-content-center">
                    <img src={logo} alt="Logo" className={styles.nav_logo_image} />
                </Col>
                <Col className="align-items-center d-flex justify-content-center">
                    <Link to='/' className='white fontXL bold' style={{ textDecoration: 'none' }}><span>Teseo.app</span></Link>
                </Col>
                <Col className="align-items-center d-flex justify-content-center">
                    {
                        user ?
                            userType === 'lifetime' || userType === 'premium' ?
                                <div className='d-flex justify-content-center align-items-center'><MdVerified size={27} className='white' /></div>
                                :
                                <div></div>
                            // <div className='buttonTeseo p-2'><Link to={'/pay'} className='bold fontM white' style={{textDecoration: 'none'}}>Teseo+</Link></div>
                            :
                            ''
                    }

                    <Link to={user ? '/profilo' : '/login'} className="socialTeseo"><FaRegUser size={27} style={{ marginRight: '10px' }} /></Link>
                    {/* <Link to={user ? '/profilo' : '/login'} className="socialTeseo">Ave, {userName}</Link> */}

                </Col>
            </Row>
        </Container>
    );
}