import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, usersCollection } from "../config/firebase-config";
import { getDocs, query, where } from "firebase/firestore";
import api from '../API/backend';

const userAuthContext = createContext();
const API_URL = process.env.REACT_APP_BACKEND_PATH;

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState({});
  const [isRegistered, setIsRegistered] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await api.get(`${API_URL}/user/data`);
      setUserData(response.data);
      setIsRegistered(response.data?.isRegistered);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User not registered");
        return;
      } else {
        console.error('Error fetching user data:', error);
        throw error;
      }
    }
  };

  async function logIn(email, password, setError) {
    if (!email || !password) {
      setError("Inserisci email e password")
      return;
    }
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      setUser(result.user);
      if (!user) {
        setError("Utente non trovato!");
        //console.log("Utente non trovato");
        return;
      } else {
        fetchUserData();
        return user;
      }
    } catch (error) {
      if (error.message === "Firebase: Error (auth/invalid-credential).") {
        setError("Credenziali non valide");
      }
      //console.log(error.message);
    }
  }

  async function signUp(email, password) {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);

      await sendEmailVerification(result.user);
      console.log("Email inviata");

      setUser(result.user);
      return result.user;

    } catch (error) {
      console.error("Error during sign up or email verification:", error);
      throw error;
    }
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function logOut() {
    return signOut(auth);
  }

  async function googleSignIn(scuola, anno, nome, phoneNumber) {
    try {
    console.log("Google Sign In");
    const googleAuthProvider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, googleAuthProvider);

    const user = result.user;
    setUser(user);

    // Login: Controlla se documento esiste gia in Firestore -> utente già registrato
    const userQuerySnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)));

    if (userQuerySnapshot.size > 0) {
      await fetchUserData();
      console.log("Utente già registrato");

      return {
        status: 'registered',
        isRegistered: true,
        uid: user.uid
      };
    } else {
      // Registrazione path 1: Utente non esiste in Firestore e clicca login con google dalla finestra /login
      if (!scuola || !anno || !nome) {
        console.log("Utente non registrato (login)");
        
        return {
          status: 'needs_registration',
          isRegistered: false,
          uid: user.uid
        };
      } else {
        // Registrazione path 2: Utente non esiste in Firestore e clicca login con google dalla finestra /registrazione
        console.log("Utente non registrato (registrazione)");

        const user_uid = user.uid;
        const response = await api.post(`${API_URL}/user/data`, { user_uid, scuola, anno, nome, phoneNumber });
        if (response.status === 200) {
          await fetchUserData();
          return {
            status: 'registration_complete',
            isRegistered: true,
            uid: user.uid
          };
        } else {
          console.log("Errore nella registrazione.");
          throw new Error("Errore nella registrazione.");
        }
      }
    } 
  }
  catch (error) {
    console.error("Error during Google Sign In:", error);
    throw error;
  }
}

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      if (currentuser) {
        fetchUserData();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, userData, isRegistered, logIn, signUp, logOut, googleSignIn, resetPassword, fetchUserData }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}