import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import ReactGA from 'react-ga4';

import TextAreaUser from '../components/TextareaUser';
import TButton from '../components/TButton';
import ResultBox from '../components/ResultBox';
import handleTranslateText from '../utils/translationUtils';
import handleAnalyzeText from '../utils/analyzeUtils';
import {sanitizeInput, getButtonInfo} from '../utils/generalUtils';

import { Circles } from 'react-loader-spinner'
import { useUserAuth } from '../components/UserAuthContext';
import { getDocs, query, where, updateDoc } from "firebase/firestore";
import { usersCollection } from "../config/firebase-config";
import {Link, useLocation, Navigate } from 'react-router-dom';
import updateLimit from '../utils/limitUtils';
import GButton from '../components/GButton';


function Greco() {
    const { user, userData, needRegistration } = useUserAuth();
    const location = useLocation();
    
    const [inputText, setInputText] = useState('Curate ut valeatis');
    const [grecoCount, setGrecoCount] = useState(userData["grecoCount"]);
    const [textError, setTextError] = useState('');
    const [loading, setLoading] = useState(false);
    const [translatedText, setTranslatedText] = useState('');
    const [analysis, setAnalysis] = useState({});
    const [progress, setProgress] = useState(0); 
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [usageCounter, setUsageCounter] = useState(0);

    const [redirectSignuUp, setRedirectSignUp] = useState(false);

    useEffect(() => {
      
      document.body.classList.add('greco-page');
      return () => {
        document.body.classList.remove('greco-page');
      };
    }, []);

    useEffect(() => {
      setGrecoCount(userData["grecoCount"]);
    }, [user, userData]);

  
    const { text, to } = getButtonInfo(location, user);

    const handleUpdateError = (errorText) => {
      setTextError(errorText);
      setError(true);
    }
  
    const handleTranslate = async (lang) => {

      if(needRegistration || userData["isRegistered"] === false){
        setRedirectSignUp(true);
        return
      }
  
      ReactGA.event({
        category: 'Button',
        action: 'Click greco translate',
        label: 'Translate greek text',
      });
      setUsageCounter(usageCounter + 1);
      handleTranslateText(inputText, lang, setLoading, setTranslatedText, setError, setTextError, handleUpdateError, setDisabled);
    }

    const handleUpdateAnalysis = (partialData, i, len) => {
      // Update component state with partial analysis data
      setAnalysis(prevData => ({ ...prevData, ...partialData }));
      setProgress((i + 1) / len * 100);
    };
  
    const handleAnalysis = async (lang) => {
      
      if(needRegistration || userData["isRegistered"] === false){
        setRedirectSignUp(true);
        return
      }

      if(grecoCount == 0 || grecoCount === 0){
        setDisabled(true);
        console.log("Greco count è zero");
      }
      /* if(grecoCount == 0 || grecoCount === 0){
        return console.log("ma dove vuoi andare");
      } */

      ReactGA.event({
        category: 'Button',
        action: 'Click greco analisi',
        label: 'Analyzed greek text',
      });
      setUsageCounter(usageCounter + 1);
      const analysisResult = await handleAnalyzeText(inputText, lang, setLoading, setAnalysis, setProgress, setTextError, setError, handleUpdateError, handleUpdateAnalysis, setDisabled, null, grecoCount);

      // if(analysisResult) {  // TODO: futuro update
      //   const updatedCounts = await updateLimit(lang);
        
      //   const grecoCountUpdated = updatedCounts["greco_count"];
      //   if(grecoCountUpdated != null) {
      //     setGrecoCount(grecoCount); 
      //   }
      // }
    };
  
    const cleanInput = (event) => {
      let textSanitized = sanitizeInput(event);
      if(textSanitized === "") {
        setDisabled(true);
      }
      else {
        setInputText(textSanitized);
        setDisabled(false);
      }
    }

    const setGreco = (event) => {
      const plainText = event.target.value.replace(/(\r\n|\n|\r)/gm, "");
      setInputText(plainText);
      setDisabled(false);
    }

    if (redirectSignuUp) {
      return <Navigate to="/complete-signup" />;
    }

    return (
      <main>
        <Container>
            <Row className='my-5'>
            <span className='bold fontXXL white text-center'>Teseo ha risolto un nuovo labirinto!</span>  
            <span className='regular fontXL white text-center'>Traduci e analizza le tue versioni di greco con l'intelligenza artificiale</span>
            <Link to={to} className='mt-3'><GButton text={text}></GButton></Link>
          </Row>
          <Row className='align-items-center justify-content-center mb-5 mx-2'>
            <TextAreaUser initialize="Incolla qui la tua versione di greco" setTextInput={setGreco} readOnly={false} lang={"gre"}></TextAreaUser>

            <span className='text-center regular fontS white mt-1'>{textError}</span>
            
            {/* <Col sm={5} md={5} lg={12} xs={6} className='d-flex justify-content-end'>Greco count: {grecoCount}</Col>    // TODO: futuro update */}
            <Col sm={11} md={8} lg={8} xs={11} className="d-flex justify-content-center mt-4">
              {loading ? 
                <Circles
                  height="40"
                  width="40"
                  color="#BB0A0B"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              : 
              ""
              }
              <TButton onClick={() => handleTranslate("gre")} text='Traduci' disabled={disabled}></TButton>
              <TButton onClick={() => handleAnalysis("gre")} text='Analizza' disabled={disabled}></TButton>
            </Col>
          </Row>

          <Row className='justify-content-center'>
              <ResultBox object={translatedText} isAnalisi={false} error={error}></ResultBox>
              <ResultBox object={analysis} isAnalisi={true} error={error} loading={loading} progress={progress}></ResultBox>
          </Row>
        </Container>
      </main>
    );
}

export default Greco;