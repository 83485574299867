import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

import SliderCard from "./SliderCard";
import styles from "../styles/Slider.module.css";

const CommentSlider = () => {
  const reviewsData = [
    { text: "Raga è 100 volte meglio di splash latino, la adorooo 😍😍", author: "_ali", place: "Liceo Classico @Firenze" },
    { text: "LA SVOLTAAA, VI AMO.", author: "flaaa", place: "Liceo Classico @Roma" },
    { text: "Non posso scoprire tutto questo solo ora", author: "Mr. rocksway", place: "Liceo Classico @Bologna" },
    { text: "Ho usato questo sito per tradurre due testi di Orazio con la relativa analisi, ho trovato 2 errori ma è comprensibile, grazie a questo sito sono riuscita a prepararmi meglio per la mia interrogazione (ho preso 9). Grazieee", author: "glo.riette_", place: "Liceo Scientifico @Napoli" },
    { text: "Ho appena dato dato un'occhiat al sito e ho controllato la traduzione che mi dava e l'ho confrontata con la correzione della stessa versione fatta dalla mia prof. è IMPECCABILE!!!", author: "iosonothomas", place: "Liceo Scientifico @Torino" },
    { text: "è un sogno che si realizza...quaranta anni dopo la mia maturità, ma pur sempre un sogno!", author: "giuffrida_annamaria", place: "Liceo Classico @Sanremo" },
    { text: "Io sono stato sempre sostenitore del 'faccio da solo al 100%', ma idea ottima! A volte ci sono frasi che proprio non tornano, è utile avere uno strumento del genere. Spero non verrà usato per non fare gli esercizi e copiare in classe perchè da uno strumento del genere se usato correttamente si può imparare tantissimo", author: "giacomo.fratini", place: "Liceo Classico @Ancona" },
    { text: "Un euro non mi basta voglio darvi le coordinate bancarie", author: "shitaldo", place: "Liceo Classico @Bologna" },
    { text: "Diplomato ormai da 4 anni, ho comunque preso Teseo+ per mio fratello che sta per iniziare il liceo. Semplicemente grandi", author: "mnl.gram", place: "Liceo Classico @Pescara" },
    { text: "Totalmente contrario, così inciterete i miei declassati coetanei a copiare dal vostro sito, così non aiutate nessuno, mettete solo persone in difficoltà se si affronta l'argomento con lungimiranza, credo anche che... OK, la smetto con questa pagliacciata, in realtà vi chiedo solo di aggiungere il Greco, grazie uagliu", author: "_ruben.di.pietro_", place: "Liceo Classico @Catania" },
    { text: "1 euro= Vi faccio un bonifico con 5 zeri", author: "rancesco.manzela_", place: "Liceo Scientifico @Bari" },
    { text: "Si guagliu scusate ma dopo 5 anni di classico ora fate uscire questa genialata????? 😭😭😭", author: "lavera_holymary", place: "Liceo Classico @Napoli" },
  ];

  const [width, setWidth] = useState(0);
  const dragSlider = useRef();
  const controls = useAnimation();

  const startSlider = () => {
    controls.start({
      x: -width,
      transition: {
        duration: 120, // Adjust the speed here
        ease: "linear",
        repeat: Infinity,
      },
    });
  };

  const stopSlider = () => {
    controls.stop();
  };

  useEffect(() => {
    const updateWidth = () => {
      setWidth(dragSlider.current.scrollWidth - dragSlider.current.offsetWidth);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    startSlider();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [width, reviewsData.length, controls]);

  return (
    <div className={styles.slider}>
      <div className={styles.slider_box}>
        <div className="text-center fontXXL bold">Dicono di Teseo</div>
        <motion.div 
          className={styles.slider_box_items} 
          ref={dragSlider}
          whileHover={{ cursor: "grab" }}
          onHoverStart={stopSlider} 
          onHoverEnd={startSlider}  
          onTapStart={stopSlider}  
          onTapCancel={startSlider}
        >
          <motion.div
            className={`${styles.slider_box_item} item_slider`}
            animate={controls}
            style={{ display: 'flex' }} 
          >
            {reviewsData.map((review, i) => (
              <SliderCard key={i} review={review} />
            ))}
            {/* Clone the reviews for a smoother loop */}
            {reviewsData.map((review, i) => (
              <SliderCard key={i + reviewsData.length} review={review} />
            ))}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default CommentSlider;