import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useUserAuth } from "./UserAuthContext";
import {getType} from "../utils/profiloUtils";
import { MdVerified } from "react-icons/md";


const Teseoplus = ({openPopup}) => {

    const { user } = useUserAuth(); 
    const [userType, setUserType] = useState('');

    /* useEffect(() => {
        const fetchUserType = async () => {
            try {
                if (user != '' && user != null) {
                    const type = await getType(user);
                    setUserType(type);
                } else {
                    setUserType('normal');
                }
            } catch (error) {
                console.error("Error fetching user type:", error);
            }
        };

        fetchUserType();
    }, [user]);

    if (userType === null) {
        return <p>Loading...</p>; 
    } */

    const handleClickPayNotAuth = () => {
        openPopup(true);
    };

    return(
        <>
            <Row className='align-items-center justify-content-center text-center'>

                {userType==='lifetime' ? 
                <Col className='d-flex align-items-center justify-content-center flex-column'>
                    <p className="fontXXL bold">Grazie!</p>
                    <div className="fontXL regular"><MdVerified size={27} className='white mx-1'/> <span>Fai parte della community di Teseo!</span></div>
                    <span className="fontL regular red">Teseo ti adora.</span>
                </Col>    
                :
                // <Col xs={12} lg={5} className='d-flex align-items-center justify-content-center flex-column'>
                //     <p className="fontXXL bold">Teseo+ è SOLD OUT, da Settembre un sacco di novità!</p>
                //     <span className="fontXXL bold">1000 di voi</span><span className='fontL regular'> sono gia entrati nella community!</span>
                //     <span className="fontXL regular underline py-3">Stay tuned 🧶</span>
                //     <div className='buttonTeseo py-2 px-3 my-4'>
                //         {user ? 
                //             <Link disabled to={'/'} className='bold fontXL white' style={{textDecoration: 'none'}}>Scopri Teseo+</Link>
                //         : 
                //             <div onClick={handleClickPayNotAuth} className='bold fontXL white' style={{textDecoration: 'none'}}>Scopri Teseo+</div>
                //         }
                //     </div>
                // </Col>
                <Col></Col>
                }
                            
            </Row>
        </>
    );
}

export default Teseoplus;