import { Col } from "react-bootstrap";
import styles from "../styles/Resultbox.module.css"
import CopyButton from "./CopyButton";

export default function ResultBox(props) {
    const isAnalisi = props.isAnalisi;
    const object = props.object;
    const loading = props.loading;
    const progress = props.progress;

    return(
        <Col lg={5} md={5} sm={5} xs={10} className={`${styles.resultBox} justify-content-center mx-3 my-3 regular fontM p-2 position-relative`}> 
            {isAnalisi ? 
                Object.keys(object).length === 0 ?
                <p className={styles.result_box_placeholder}>Qui troverai l'analisi del testo</p>
                :
                <div className="m-0 p-0">
                    {loading && <progress className="d-flex align-items-center mb-3" value={progress} max={100} />}
                    <ul>
                    {Object.keys(object).map((word, index) => (
                    <li key={index}>
                        <strong>{word}:</strong> {
                            object[word]
                            .split("\n")               // Split the string by \n
                            .map((line, idx) => (       // Map over the array and render each line separately
                              <span key={idx}>
                                {line}
                                <br />                 {/* Add a line break after each line */}
                              </span>
                            ))
                        }
                    </li>
                    ))}
                    </ul>
                </div>
            :
                object ? 
                    <p>{object}
                    </p>
               :
                <p className={styles.result_box_placeholder}>
                    Qui troverai il testo tradotto
                </p>
            }
            {/*isAnalisi ? '' 
                : 
                <div style={{bottom:10, position:'absolute', right:10}}>
                <CopyButton text={object} />
                </div>
        */}
        </Col>
    );
}