// General imports
import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import ReactGA from 'react-ga4';
import { Circles } from 'react-loader-spinner'
import { Link, useLocation, Navigate } from 'react-router-dom';
// Component imports
import Signup from './signup';
import TextAreaUser from '../components/TextareaUser';
import TButton from '../components/TButton';
import ResultBox from '../components/ResultBox';
import CommentSlider from '../components/CommentSlider';
import { useUserAuth } from "../components/UserAuthContext";
import DynamicAnalysis from '../components/DynamicAnalysis';
import GButton from '../components/GButton';
import CustomHead from '../components/CustomHead';
import Teseoplus from '../components/Teseoplus';
import OcrWidget from '../components/OcrWidget';
// Utils imports
import handleTranslateText from '../utils/translationUtils';
import handleAnalyzeText from '../utils/analyzeUtils';
import {sanitizeInput, getButtonInfo} from '../utils/generalUtils';
import updateLimit from '../utils/limitUtils';
import api from '../API/backend';
import axios from 'axios';

function Home() {
  const { user, userData, needRegistration } = useUserAuth();
  const location = useLocation();

  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState('');
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // Progress state
  const [disabled, setDisabled] = useState(true);
  const [openSignup, setOpenSignup] = useState(false);
  
  const [latinoCount, setLatinoCount] = useState(userData["latinoCount"]);

  const [showTranslation, setShowTranslation] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false);

  const [redirectSignuUp, setRedirectSignUp] = useState(false);


  useEffect(() => {
    document.body.classList.add('home-page');
    return () => {
      document.body.classList.remove('home-page');
      };
  }, []);

  useEffect(() => {
    setLatinoCount(userData["latinoCount"]);
  }, [user, userData]);
  
  const { text, to } = getButtonInfo(location, user);

  const handleUpdateError = (errorText) => {
    setTextError(errorText);
    setError(true);
  }  

  const handleTranslate = async (lang) => {
    if(!user) {
      setOpenSignup(true);
      return
    }
    else if(needRegistration || userData["isRegistered"] === false){
      setRedirectSignUp(true);
    }
    else {
      ReactGA.event({
        category: 'Button',
        action: 'Click button translate',
        label: 'Translate text',
      });

      setShowTranslation(true);
      handleTranslateText(inputText, lang, setLoading, setTranslatedText, setError, setTextError, handleUpdateError, setDisabled);
    }
  }

  const handleUpdateAnalysis = (partialData, i, len) => {
    // Update component state with partial analysis data
    setAnalysis(prevData => ({ ...prevData, ...partialData }));
    setProgress((i + 1) / len * 100);
  };

  const handleAnalysis = async (lang) => {
    if(!user) {
      setOpenSignup(true);
      return
    }
    else if(needRegistration || userData["isRegistered"] === false){
      setRedirectSignUp(true);
      return
    }
    
    if(latinoCount == 0 || latinoCount === 0){
      setDisabled(true);
      console.log("Latino count è zero");
    }
    
    else {
    
    ReactGA.event({
      category: 'Button',
      action: 'Click button analisi',
      label: 'Analyzed text',
    });
    
    setShowAnalysis(true);
    const analysisResult = await handleAnalyzeText(inputText, lang, setLoading, setAnalysis, setProgress, setTextError, setError, handleUpdateError, handleUpdateAnalysis, setDisabled, latinoCount, null);
    
    // if(analysisResult) {   // TODO: futuro update
    //   const updatedCounts = await updateLimit(lang);
      
    //   const latinoCountUpdated = updatedCounts["latino_count"];
    //   if(latinoCountUpdated != null) {
    //     setLatinoCount(latinoCountUpdated);
    //   }
    // }
    }
  };

  const cleanInput = (event) => {
    let textSanitized = sanitizeInput(event);
    if(textSanitized === "") {
      setDisabled(true);
    }
    else {
      setInputText(textSanitized);
      setDisabled(false);
    }
  }

  const closePopup = () => {
    setOpenSignup(false);
  }

  if (redirectSignuUp) {
    return <Navigate to="/complete-signup" />;
  }

  return (
    <main className="App">
      <CustomHead 
        title="Teseo.app | Traduci e analizza" 
        description="Traduci e analizza la tua versione con l'intelligenza artificiale."
        keywords="teseo.app, teseo, latino, greco, traduzione, analisi, versione"
        author="Teseo"
        canonicalUrl="https://teseo.app/"
      />
      <Container>
        {openSignup && <div className='blurred-background'></div>}
        {openSignup && <Signup closePopup={closePopup} popup={true}></Signup>}

        <Row className='my-5'>
          <span className='bold fontXXL white text-center'>Le versioni di latino sono un labirinto?</span>  
          <span className='regular fontXL white text-center'>Traduci e analizza la tua versione con l'intelligenza artificiale</span>
          <Link to={to} className='mt-3'><GButton text={text}></GButton></Link>
        </Row>

        <Row className='align-items-center justify-content-center mb-5 mx-2'>
            <TextAreaUser initialize="Incolla qui la tua versione di latino" defaultValue={inputText} setTextInput={cleanInput} readOnly={false}></TextAreaUser>

          <span className='text-center regular fontS white mt-1'>{textError}</span>
          {/* <Col sm={5} md={5} lg={12} xs={6} className='d-flex justify-content-end'>Latino count: {latinoCount}</Col>  // TODO: futuro update*/} 
          <Col sm={11} md={8} lg={8} xs={11} className="d-flex justify-content-center mt-4">
            {loading ? 
              <Circles
              height="40"
              width="40"
              color="#BB0A0B"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              />
            : 
            ""
            }
              <TButton onClick={() => handleTranslate("lat")} text='Traduci' disabled={disabled}></TButton>
              <TButton onClick={() => handleAnalysis("lat")} text='Analizza' disabled={disabled}></TButton>
          </Col>
        </Row>

        {/* TODO: next update */}
        {/* <Row className='justify-content-center'>
          <OcrWidget />
        </Row> */}
        
        {/* TODO: next update */}
        <Row className='justify-content-center'>
          {showTranslation ? <ResultBox object={translatedText} isAnalisi={false} error={error}></ResultBox> : ''}
          {showAnalysis ? <ResultBox object={analysis} isAnalisi={true} error={error} loading={loading} progress={progress}></ResultBox> : ''}
        </Row>
          
        <Row className="mt-5 mb-5">
          <CommentSlider />
        </Row>

        <Teseoplus openPopup={setOpenSignup}></Teseoplus> 
      </Container> 
    </main>
  );
}

export default Home;