import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../components/UserAuthContext";
import api from '../API/backend';
import styles from "../styles/PopupTeseo.module.css";
import { IoMdClose } from "react-icons/io";

const API_URL = process.env.REACT_APP_BACKEND_PATH;

const Signup = ({ closePopup, popup }) => {
    const { user, fetchUserData } = useUserAuth();
    let navigate = useNavigate();

    const [nome, setNome] = useState("");
    const [scuola, setScuola] = useState("");
    const [anno, setAnno] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [completePhoneNumber, setCompletePhoneNumber] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [step, setStep] = useState(1);
    const [verificationCode, setVerificationCode] = useState("");
    const [submitAttempts, setSubmitAttempts] = useState(0);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [dataConsent, setDataConsent] = useState(false);


    const handleSubmitStep1 = (e) => {
        e.preventDefault();
        setError("");
        if (scuola && anno && nome) {
            setStep(3);   // mettere a 2 per abilitare il telefono
        } else {
            setError("Completa tutti i campi prima di continuare!");
        }
    };

    // const handlePhoneNumberSubmit = async (e) => {
    //     e.preventDefault();
    //     setError("");

    //     if (submitAttempts >= 5) {
    //         setError("Hai superato il numero massimo di tentativi.");
    //         return;
    //     }

    //     if (!phoneNumber) {
    //         setError("Inserisci prima un numero di telefono.");
    //         return;
    //     }

    //     const phoneNumberRegex = /^[0-9]{6,14}$/;
    //     if (!phoneNumberRegex.test(phoneNumber)) {
    //         setError("Inserisci un numero di telefono valido");
    //         return;
    //     }

    //     try {
    //         let fullPhoneNumber = `+39${phoneNumber}`; // Solo Italia
    //         setCompletePhoneNumber(fullPhoneNumber);
    //         const result = await api.post(`${API_URL}/public/user/send-sms-code`, { phone_number: fullPhoneNumber });
    //         if (result.status === 200) {
    //             console.log("Codice inviato");
    //             setMessage("Codice di verifica inviato.")
    //         } else {
    //             setError("Errore nell'invio del codice di verifica. Riprova.");
    //         }
    //         setSubmitAttempts(submitAttempts + 1);
    //     } catch (err) {
    //         setError("Qualcosa è andato storto. Riprova.");
    //         // TODO: migliorare il messaggio di errore
    //     }
    // };

    // const handleVerificationCodeSubmit = async (e) => {
    //     e.preventDefault();
    //     setError("");

    //     if (!verificationCode) {
    //         setError("Inserisci prima il codice di verifica.");
    //         return;
    //     }

    //     const codeRegex = /^[0-9]{6}$/;
    //     if (!codeRegex.test(verificationCode)) {
    //         setError("Inserisci un codice di verifica valido");
    //         return;
    //     }
    //     try {
    //         const result = await api.post(`${API_URL}/public/user/verify-sms-code`, { phone_number: completePhoneNumber, code: verificationCode });
    //         if (result.status === 200) {
    //             console.log("Codice verificato");
    //         } else {
    //             setError("Errore nella verifica del codice. Riprova.");
    //         }

    //         // Verifica se il telefono è già stato registrato
    //         const response = await api.post(`${API_URL}/public/user/check-phone-number-uniqueness`, { phone_number: completePhoneNumber });
    //         if (response.status === 200) {
    //             console.log("Numero di telefono non registrato");
    //             setStep(3);
    //         } else {
    //             setError("Numero di telefono già registrato");
    //             setStep(2);
    //         }

    //     } catch (err) {
    //         setError("Qualcosa è andato storto. Riprova.");
    //         // TODO: migliorare il messaggio di errore
    //     }
    // };

    const handleSubmitStep3 = async (e) => {
        e.preventDefault();
        setError("");
        if (acceptTerms && dataConsent) {
            try {
                let user_uid = user.uid;
                const response = await api.post(`${API_URL}/user/data`, { user_uid, scuola, anno, nome, phoneNumber });
                if (response.status === 200) {
                    fetchUserData();
                    navigate("/profilo");
                } else {
                    console.log("Errore nella registrazione.");
                    throw new Error("Errore nella registrazione.");
                }
            } catch (err) {
                setError(err.message);
            }
        } else {
            setError("Dai accetta, questa parte la odiamo anche noi");
        }
    };

    return (
        <>
            <div className={styles.normalSizeBox}>
                <div className={`${popup ? styles.popup_container : ''} d-flex align-items-center justify-content-center align-items-center`}>
                    <div className={`${styles.boxTeseo} white pt-4 px-5`}>
                        {popup ?
                            <div className={`${styles.icon_close} fontL d-flex align-items-center justify-content-center`} onClick={closePopup}>
                                <IoMdClose />
                            </div>
                            :
                            null
                        }
                        {step === 1 && (
                            <>
                                <h2 className="bold text-center" >Completa la registrazione</h2>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <Form onSubmit={handleSubmitStep1}>
                                    <Form.Group className="mb-3" controlId="formNameSurname">
                                        <Form.Control
                                            type="text"
                                            placeholder="Nome e Cognome"
                                            onChange={(e) => setNome(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 regular" controlId="formScuola">
                                        <Form.Label>Seleziona la tua scuola:</Form.Label>
                                        <Form.Select
                                            onChange={(e) => setScuola(e.target.value)}
                                        >
                                            <option value="">Seleziona</option>
                                            <option value="classico">Liceo Classico</option>
                                            <option value="scientifico">Liceo Scientifico</option>
                                            <option value="linguistico">Liceo Linguistico</option>
                                            <option value="pedagogico">Liceo Pedagogico</option>
                                            <option value="universita">Università</option>
                                            <option value="altro">Altro</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3 regular" controlId="formAnno">
                                        <Form.Label>Seleziona in quale anno sei:</Form.Label>
                                        <Form.Select
                                            onChange={(e) => setAnno(e.target.value)}
                                        >
                                            <option value="">Seleziona</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="0">Altro</option>
                                        </Form.Select>
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="formNameSurname">  da fare in futuro
                                    <Form.Control
                                        type="text"
                                        placeholder="Codice referral (opzionale)"
                                        onChange={(e) => setReferral(e.target.value)}
                                    />
                                    </Form.Group> */}

                                    <div className="d-grid gap-2">
                                        <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                            Continua
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}

                        {/* {step === 2 && (
                            <>
                                <h2 className="mb-3 bold text-center regular">Registrati</h2>
                                {error && <Alert variant="danger">{error}</Alert>}
                                {message && <Alert variant="success">{message}</Alert>}
                                <Form onSubmit={handlePhoneNumberSubmit}>
                                    <Form.Group controlId="formPhoneNumber">
                                        <Form.Label>Numero di telefono</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Es. 3826401638"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button type="submit" className="buttonTeseo bold fontL my-3">Invia codice di verifica</Button>
                                    </div>
                                </Form>

                                <Form onSubmit={handleVerificationCodeSubmit} className="mt-4">
                                    <Form.Group controlId="formVerificationCode">
                                        <Form.Label>Codice di verifica</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Es. 298357"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button type="submit" className="buttonTeseo bold fontL my-3">Verifica</Button>
                                    </div>
                                </Form>
                                <div id="recaptcha-container"></div>
                            </>
                        )} */}

                        {step === 3 && (
                            <>
                                <h2 className="mb-3 bold text-center regular">Registrati</h2>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <Form onSubmit={handleSubmitStep3}>
                                    <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => setAcceptTerms(e.target.checked)}
                                            label={
                                                <span>
                                                    Accetto i{' '}
                                                    <a
                                                        href="https://www.iubenda.com/termini-e-condizioni/27118703"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className='regular white m-2'
                                                    >
                                                        Termini e Condizioni
                                                    </a>
                                                </span>
                                            }
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => setDataConsent(e.target.checked)}
                                            label={
                                                <span>
                                                    Acconsento alla{' '}
                                                    <a
                                                        href="https://www.iubenda.com/privacy-policy/27118703"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className='regular white m-2'
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                </span>
                                            }
                                        />
                                    </Form.Group>
                                    <hr />
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" type="Submit" className="buttonTeseo bold fontL">
                                            Fatto!
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
